import React from "react";
import ProductItem from "./ProductItem";
import "./ProductGrid.css";

const products = [
  // Array of product objects
  {
    id: 1,
    title: "Product 1",
    price: "$20",
    image: "https://via.placeholder.com/215x223",
    freeShipping: true,
    availability: "Only 12 in stock",
  },
  {
    id: 2,
    title: "Product 2",
    price: "$30",
    image: "https://via.placeholder.com/215x223",
    freeShipping: false,
    availability: "Only 5 in stock",
  },
  {
    id: 3,
    title: "Product 2",
    price: "$30",
    image: "https://via.placeholder.com/215x223",
    freeShipping: false,
    availability: "Only 5 in stock",
  },
  {
    id: 4,
    title: "Product 2",
    price: "$30",
    image: "https://via.placeholder.com/215x223",
    freeShipping: false,
    availability: "Only 5 in stock",
  },
  {
    id: 5,
    title: "Product 2",
    price: "$30",
    image: "https://via.placeholder.com/215x223",
    freeShipping: false,
    availability: "Only 5 in stock",
  },
  {
    id: 6,
    title: "Product 2",
    price: "$30",
    image: "https://via.placeholder.com/215x223",
    freeShipping: false,
    availability: "Only 5 in stock",
  },
  // Add more products here
];

const ProductGrid = () => {
  return (
    <div className="product-grid">
      {products.map((product) => (
        <ProductItem key={product.id} product={product} />
      ))}
    </div>
  );
};

export default ProductGrid;
