import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link, Outlet } from "react-router-dom";
import { Spiral as Hamburger } from "hamburger-react";
import { ReactComponent as Logo } from "../../img/logo/logo_map_black.svg";
import { GrDown } from "react-icons/gr";
import "./Menu.css";
import MobileMenu from "./MobileMenu";
import { useTranslation } from "react-i18next";

export default function Menu() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);

  const [menuStatus, setMenuStatus] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setMenuStatus(!menuStatus);
  }, [location.pathname]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleDropdown2 = () => setDropdownOpen2(!dropdownOpen2);

  return (
    <div className="app">
      <div className="menu">
        <div className="menu-container">
          <ul className="menu-list">
            <li className="menu-list-item-logo">
              <Link to="/">
                America Foundation
                <Logo width="30px" />
              </Link>
            </li>
            {/* HERE */}
            <li className="menu-list-item border-left mobile-none dropdown2">
              {t("menu.wwo2")} <GrDown size={18} />
              <div className="dropdown-menu2">
                <Link className="bdropdown" to="/balsa">
                  {t("menu.af2")}
                  <GrDown className="grdownb" color="green" size={18} />
                  <div className="submenu">
                    <Link to="/balsa/glued_blocks">
                      {t("Balsa Glued Blocks")}
                    </Link>
                    <Link to="/balsa/raw_planks">{t("Balsa Raw Planks")}</Link>
                    <Link to="/balsa/sheets">{t("Balsa Sheets")}</Link>
                    <Link to="/balsa/sticks">{t("Balsa Sticks")}</Link>
                    <Link to="/balsa/triangles">{t("Balsa Triangles")}</Link>
                    <Link to="/balsa/rods">{t("Balsa Wood Rods")}</Link>
                  </div>
                </Link>

                <Link className="tdropdown" to="/teak">
                  {t("menu.bf2")}
                  <GrDown className="grdownt" color="green" size={18} />
                  <div className="submenu1">
                    <Link to="/teak/raw_logs">{t("Teak Raw Logs")}</Link>
                    <Link to="/teak/raw_planks">{t("Teak Raw Planks")}</Link>
                    <Link to="/teak/slabs">{t("Teak Deaking")}</Link>
                    <Link to="/teak/flooring">{t("Teak Flooring")}</Link>
                    <Link to="/teak/beams">{t("Teak Beams")}</Link>
                    <Link to="/teak/slabs">{t("Teak Slabs")}</Link>
                    <Link to="/teak/glued_blocks">
                      {t("Teak Glued Blocks")}
                    </Link>
                    <Link to="/teak/plywood">{t("Teak Plywood")}</Link>
                    <Link to="/teak/veneers">{t("Teak Veneers")}</Link>
                    <Link to="/teak/Strips">{t("Teak Strips")}</Link>
                    <Link to="/teak/rods">{t("Teak Rods")}</Link>
                  </div>
                </Link>
              </div>
            </li>
            {/* HERE */}
            <li className="menu-list-item mobile-none">
              <Link to="/Learn">{t("menu.l")}</Link>
            </li>
            <li className="menu-list-item mobile-none">
              <Link to="/support">{t("menu.s")}</Link>
            </li>
            <li className="menu-list-item border-left2 mobile-none dropdown">
              {t("menu.wwo")}
              <GrDown size={18} />
              <div className="dropdown-menu">
                <Link to="/agricultural_funds">{t("menu.af")}</Link>
                <Link to="/banking_funds">{t("menu.bf")}</Link>
              </div>
            </li>

            <li
              className="menu-list-item mobile"
              onClick={() => setMenuStatus(!menuStatus)}
            >
              <Hamburger
                toggled={menuStatus}
                toggle={setMenuStatus}
                color="#000"
                size={30}
              />
            </li>
          </ul>
          <ul className="user-menu mobile-none">
            {/* <li className="landing_page_login">
              <Link to="/LogIn">Log in</Link>
            </li> */}
            {/* <li className="landing_page_signup">
              <Link to="/SignUp">Sign up</Link>
            </li> */}
            <li className="landing_page_signup">
              <Link to="https://wa.link/l9r1m8">{t("menu.c")}</Link>
            </li>
          </ul>
        </div>
        {menuStatus ? <MobileMenu setMenuStatus menuStatus /> : null}
      </div>
      <Outlet />
    </div>
  );
}
