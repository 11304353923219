import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          menu: {
            wwo: "For Investors",
            af: "Agricultural Funds",
            bf: "Banking Funds",
            wwo2: "Products",
            af2: "Balsa",
            bf2: "Teak",
            l: "Learn",
            s: "Support",
            c: "Start",
          },
          landing: {
            hero_per: "Earn up to 50% APY",
            hero_btn: "Learn more",
            cool: "Check out our latest",
            i: "Investing",
            byp: "Build your portfolio starting with just $100",
            iia: "Invest in agricultural plantations and plantations shares at your pace and commission-free.",
            bf: "Banking Funds",
            dri: "Dive right in without the commission fees",
            ghr: "Get higher returns for your cash—and start with as little as $500. Buy from Banks located in Ecuador, Spain, Brasil, Argentina, Colombia, Peru and more.",
            per: "8.60%",
            hr: "Highest returns for $USD.",
            lf: "Look for 'Banco Pichincha - Ecuador' in the AF App-and see they latest interest rates",
            afp: "AF Protection Guarantee",
            wwh: "We work hard to keep your data safe and secure.",
            wpya: "We protect your account from unauthorized activity.",
            wpmfa: "We provide multi-factor authentication on all accounts.",
            wgyb: "We’ve got your back. We’re available to you 365 days a year.",
            bbi: "Become a better investor on the go, right in the app",
            hiap: "Here’s a preview of the things you can learn when you sign up.",
            ngi: "Join a new generation of investors",
          },
          learn: {
            ib: "Investing basics",
            fj: "The building blocks of your financial journey",
            fjd: "What you need to know about investing from the get-go.",
            i101: "Investing 101",
            i101d:
              "A good place to start. Get the low-down before you dive in.",
            wh: "What is a Hectare?",
            whd: "10,000 sq. meters of land for specific crop/tree cultivation is a hectare plantation.",
            wi: "What is an Investment?",
            wid: "An investment is an asset bought by an individual or organization with the expectation that it will generate some future income or profit",
            wfti: "What is a Fixed Term Investment?",
            wftid:
              "A fixed-term investment is a financial arrangement where a set amount of money is invested for a predetermined period, earning a fixed interest or return.",
            tmtl: "There's more to learn",
            gsws: "Getting started with shares",
            gswsd: "Learn how much capital do you need to start investing.",
            ltb: "Loaning to banks",
            ltbd: "Before you start investing, take a moment to learn about what your money would be used for.",
            rm: "Risk management",
            rmd: "Investing can be risky business, and it’s important for any investor to have a handle on their personal risk management strategy.",
            tl: "The library",
            tld: "here’s always more to learn when it comes to investing. Check out our entire library.",
            oc: "OneTime Crops?",
            wp: "What is a Portafolio?",
            lc: "Lifetime Crops?",
            wd: "What is Diversification?",
            sl: "See Library",
          },
          support: {
            h: "Hello!",
            hh: "How can we help?",
            gs: "Getting Started",
            wgs: "What you need to get started",
            afi: "Investments you can make on America Foundation",
            oa: "Open my account",
          },
          banking_funds: {
            t: "Get higher returns for your cash",
            td: "Buy fixed term investments with no commission fees.*",
            gs: "Get Started",
            su: "Sign up fast",
            sud: "Like faster than ordering takeout fast",
            ss: "Start small",
            ssd: "There’s no need to invest a fortune-start with as little as $1",
            ff: "Find your favorites",
            ffd: "Trade & Invest in Banking Funds from the country and currency you like, Ecuador, Spain, Brasil, Argentina, Colombia, Peru and more",
          },
          agricultural_funds: {
            o: "Onetime",
            o2: "Crops Return investment and profit Quickly",
            od: "this crops are quick to grow and will be process for production depending on the plant, resulting on a return of your investment and profit. Ex: Corn, Pineapple, Balsa",
            od2: "Onetime Crops Disclosures",
            l: "Lifetime",
            l2: "Crops High cost, long return",
            ld: "this crops take a considerate time to grow but they provide fruits for a long time after resulting on a constant cashflow for the lifetime of the plant. Ex: Avocado, Cocoa trees",
            ld2: "Lifetime Crops Disclosures",
            s: "Stable",
            i: "Investment",
            sid: "Don't Invest your money on Stocks or Crypto assets which are very volatile. Guaranteed your portafolio growth with Insured Deposits.",
            sid2: "Portafolio & Insure Deposits Disclosures",
            t: "Investing doesn’t have to be that hard.",
            td: "Access Plantations, Banking Funds, and more. Oh, and no commission fees. That’s right. Zero. Nada. Zilch. Your first 50$ investment is even on us. ",
            p: "Plantations",
            p2: "Funds offered through America Foundation Financial. Other fees may apply. See our",
            p3: "Fee Schedule",
            p4: "for more details.",
            p: "Plantations",
            pd2: "Invest in your favorite crops",
            ff: " With thousands of plantations to choose from and Fractional Shares, you can put in as little as $10 towards the agricultural fund that will potentially add value to you and your future.",
            ca: "Conditions apply",
          },
        },
      },
      es: {
        translation: {
          menu: {
            wwo: "Para Inversionistas",
            af: "Fondos Agricolas",
            bf: "Fondos Bancarios",
            wwo2: "Productos",
            af2: "Balsa",
            bf2: "Teca",
            l: "Educacion",
            s: "Ayuda",
            c: "Empezar",
          },
          landing: {
            hero_per: "Gana hasta 50% APY",
            hero_btn: "Aprender mas",
            cool: "Actualizate",
            i: "Invierte",
            byp: "Construye tu portafolio desde $100",
            iia: "Invierte en Plantaciones Agricolas y en Acciones de tus Plantaciones favoritas a tu paso y sin-comission.",
            bf: " Bitcoin",
            dri: "Empieza en el mundo de las cryptomonedas",
            ghr: "El Dolar es una de las mejores monedas que mantiene su valor pero igualmente se devalua, asegura tu patrimonio en con Bitcoin o otras cryptomonedas, nosotros te ayudamos a educarte, comprar y mantener tu dinero digital seguro y bajo llave.",
            per: "8.60%",
            hr: "Retornos mas Altos en $USD.",
            lf: "Consigue un mayor retorno por tu cash y empieza desde tan solo $500. Compra de Bancos en Ecuador, España, Brasil, Argentina, Colombia, Peru y mas.",
            afp: "AF Proteccion Garantizada",
            wwh: "Trabajamos duro para mantener sus datos seguros y protegidos.",
            wpya: "Protegemos su cuenta de actividades no autorizadas.",
            wpmfa:
              "Proporcionamos autenticación multifactor en todas las cuentas.",
            wgyb: "Te cubrimos. Estamos disponibles para usted los 365 días del año.",
            bbi: "Conviértete en un mejor inversor desde tu telefono.",
            hiap: "Aquí tienes una vista previa de lo que puedes aprender cuando te registras.",
            ngi: "Unete a una nueva generación de inversores",
          },
          learn: {
            ib: "Basicos del Inversor",
            fj: "Los bloques para tu viaje financiero",
            fjd: "Lo que necesitas saber acerca de la inversión desde el principio.",
            i101: "Inversiónes 101",
            i101d:
              "Un buen lugar para empezar. Obtén la información básica antes de sumergirte.",
            wh: "¿Qué es una hectárea?",
            whd: "Una plantación de hectáreas es un terreno de 10,000 metros cuadrados destinado al cultivo específico de un cultivo o árbol.",
            wi: "¿Qué es una inversión?",
            wid: "Una inversión es un activo comprado por un individuo u organización con la expectativa de que generará algún ingreso o beneficio futuro.",
            wfti: "¿Qué es una inversión a plazo fijo?",
            wftid:
              "Una inversión a plazo fijo es un acuerdo financiero en el que se invierte una cantidad fija de dinero por un período predeterminado, generando un interés o rendimiento fijo.",
            tmtl: "Hay más por aprender",
            gsws: "Empezando con acciones",
            gswsd: "Aprende cuánto capital necesitas para empezar a invertir.",
            ltb: "Préstamos a bancos",
            ltbd: "Antes de empezar a invertir, tómate un momento para aprender en qué se utilizará tu dinero.",
            rm: "Gestión de riesgos",
            rmd: "Invertir puede ser arriesgado, y es importante que cualquier inversor tenga un control sobre su estrategia de gestión de riesgos personales.",
            tl: "La biblioteca",
            tld: "Siempre hay más por aprender cuando se trata de inversiones. Echa un vistazo a toda nuestra biblioteca.",
            oc: "Cultivos de una sola vez",
            wp: "¿Qué es un portafolio?",
            lc: "Cultivos de por vida",
            wd: "¿Qué es la diversificación?",
            sl: "Ver biblioteca",
          },
          support: {
            h: "¡Hola!",
            hh: "¿Cómo podemos ayudarte?",
            gs: "Empezando",
            wgs: "Lo que necesitas para comenzar",
            afi: "Inversiones que puedes hacer en America Foundation",
            oa: "Abrir mi cuenta",
          },
          banking_funds: {
            t: "Obtén rendimientos más altos para tu efectivo",
            td: "Compra inversiones a plazo fijo sin comisiones.*",
            gs: "Empezar",
            su: "Regístrate rápido",
            sud: "Tan rápido como pedir comida para llevar",
            ss: "Comienza pequeño",
            ssd: "No es necesario invertir una fortuna, comienza con tan solo $1",
            ff: "Encuentra tus favoritos",
            ffd: "Opera e invierte en Fondos Bancarios del país y la moneda que prefieras, Ecuador, España, Brasil, Argentina, Colombia, Perú y más",
          },
          agricultural_funds: {
            o: "Única vez",
            o2: "Rendimiento y beneficio rápido de cultivos",
            od: "estos cultivos crecen rápidamente y se procesarán para la producción según la planta, resultando en un retorno de tu inversión y beneficio. Ejemplo: Maíz, Piña, Balsa",
            od2: "Divulgaciones de Cultivos Únicos",
            l: "Vida útil",
            l2: "Cultivos de alto costo, retorno a largo plazo",
            ld: "estos cultivos tardan un tiempo considerable en crecer, pero proporcionan frutas durante mucho tiempo, lo que resulta en un flujo constante de efectivo durante la vida útil de la planta. Ejemplo: Aguacate, Árboles de cacao",
            ld2: "Divulgaciones de Cultivos de por Vida",
            s: "Estable",
            i: "Inversión",
            sid: "No inviertas tu dinero en acciones o activos criptográficos que son muy volátiles. Asegura el crecimiento de tu cartera con depósitos asegurados.",
            sid2: "Divulgaciones de Cartera y Depósitos Asegurados",
            t: "Invertir no tiene que ser tan difícil.",
            td: "Accede a plantaciones, fondos bancarios y más. Ah, y sin comisiones. Así es. Cero. Nada. Ni un centavo. Incluso tu primera inversión de $50 es gratis.",
            p: "Plantaciones",
            p2: "Fondos ofrecidos a través de America Foundation Financial. Pueden aplicarse otras tarifas. Consulta nuestra",
            p3: "Tabla de Tarifas",
            p4: "para más detalles.",
            pd2: "Invierte en tus cultivos favoritos",
            ff: " Con miles de plantaciones para elegir y acciones fraccionadas, puedes invertir tan solo $10 en el fondo agrícola que potencialmente añadirá valor a ti y a tu futuro.",
            ca: "Se aplican condiciones",
          },
        },
      },
    },
  });

export default i18n;
