import React, { useEffect } from "react";
import TextHero from "../../components/TextHero/TextHero";
import PrimaryBtn from "../../components/Btns/PrimaryBtn";
import hero1 from "../../img/heros/hero-crypto.png";
import hero2 from "../../img/heros/hero-crypto-2.png";
import fastIcon from "../../img/icons/fast-icon.png";
import dollarIcon from "../../img/icons/dollar-icon.png";
import WorldMoneyIcon from "../../img/icons/world-money-icon.png";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";
import Sidebar from "../../components/Shop/Sidebar";
import ProductGrid from "../../components/Shop/ProductGrid";
import "./BalsaWood.css";

export default function Crypto({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  const { t, i18n } = useTranslation();
  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);

  return (
    <>
      <div className="crypto-section">
        <div className="bshop">
          <Sidebar />
          <ProductGrid />
        </div>
      </div>
    </>
  );
}
