import React from "react";
import "./Sidebar.css";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <h2>Filters</h2>
      <div className="filter-section">
        <h3>Price</h3>
        <label>
          <input type="checkbox" /> $0 - $25
        </label>
        <label>
          <input type="checkbox" /> $25 - $50
        </label>
        <label>
          <input type="checkbox" /> $50 - $100
        </label>
        <label>
          <input type="checkbox" /> $100 - $200
        </label>
      </div>
    </div>
  );
};

export default Sidebar;
