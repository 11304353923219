import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";

import "./Menu.css";

const MobileMenu = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);
  const lng = navigator.language;

  return (
    <div className="mobile-menu _fade-animation top-border">
      <ul>
        <li className="mobile-link">
          <Link to="/balsa">{t("Balsa")}</Link>
        </li>
        <li className="mobile-link">
          <Link to="/teak">{t("Teak")}</Link>
        </li>

        <li className="mobile-link">
          <Link to="/Learn">{t("menu.l")}</Link>
        </li>
        <li className="mobile-link">
          <Link to="/support">{t("menu.s")}</Link>
        </li>
        <li className="mobile-link">
          <Link to="/agricultural_funds">{t("menu.af")}</Link>
        </li>
        <li className="mobile-link">
          <Link to="/banking_funds">{t("menu.bf")}</Link>
        </li>
        {/* <li className="mobile-link">
          <Link to="/LogIn">Login</Link>
        </li>
        <li className="mobile-link">
          <Link to="/SignUp">Sign up</Link>
        </li> */}
        <li className="landing_page_signup">
          <Link to="https://wa.link/l9r1m8">{t("menu.c")}</Link>
        </li>
      </ul>
    </div>
  );
};

export default MobileMenu;
