import React from "react";
import "./ProductItem.css";

const ProductItem = ({ product }) => {
  return (
    <div className="product-item">
      <img src={product.image} alt={product.title} />
      <h4>{product.title}</h4>
      <p>{product.price}</p>
      {product.freeShipping && <p>Free Shipping</p>}
      <p>{product.availability}</p>
      <button>Add to Cart</button>
    </div>
  );
};

export default ProductItem;
