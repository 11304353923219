import React, { useState, createContext } from "react";
import "./App.css";
import { Route, Routes, Outlet } from "react-router-dom";
import Header from "./Inside App/Header/Header";
import Cash from "./Inside App/Cash/Cash";
import FreeTrees from "./Inside App/Free Trees/FreeTrees";
import Account from "./Inside App/Account/Account";
import Investing from "./Inside App/Investing/Investing";
import Investments from "./Inside App/Investments/Investments";
import Agriculture from "./Inside App/Investments/Agriculture";
import Balsa from "./Inside App/Investments/Agriculture/Balsa";
import Funds from "./Inside App/Investments/Funds";
import Account_Investing from "./Inside App/Account/Account_Investing";
import Account_Cryto from "./Inside App/Account/Account_Cryto";
import Account_Transfers from "./Inside App/Account/Account_Transfers";
import Account_Recurring from "./Inside App/Account/Account_Recurring";
import Account_Statements from "./Inside App/Account/Account_Statements";
import Account_History from "./Inside App/Account/Account_History";
import Account_Settings from "./Inside App/Account/Account Settings/Account_Settings";
import Account_Help from "./Inside App/Account/Account_Help";
import Account_Settings_PersonalInfo from "./Inside App/Account/Account Settings/Account_Settings_PersonalInfo";
import Account_Settings_SecurityAndPrivacy from "./Inside App/Account/Account Settings/Account_Settings_SecurityAndPrivacy";
import Account_Settings_Beneficiaries from "./Inside App/Account/Account Settings/Account_Settings_Beneficiaries";
import Account_Settings_AppAppearance from "./Inside App/Account/Account Settings/Account_Settings_AppAppearance";
import LogIn from "./Outside App/pages/Login/LogIn";
import SignUp from "./Outside App/pages/SignUp/SignUp";
import Menu from "./Outside App/components/Menu/Menu";
import Landing from "./Outside App/pages/Landing/Landing";
import Learn from "./Outside App/pages/Learn/Learn";
import Support from "./Outside App/pages/Support/Support";
import P404 from "./Outside App/pages/P404/P404";
import Invest from "./Outside App/pages/Invest/Invest";
import Crypto from "./Outside App/pages/Crypto/Crypto";
import ProtectedRoutes from "./Outside App/ProtectedRoutes";
import { AuthContextProvider } from "./AuthContext";
import ForgotPassword from "./Outside App/pages/ForgotPassword";
import Footer from "./Outside App/components/Footer/Footer";
import Library from "./Outside App/pages/Library/Library";
import HectareArticle from "./Outside App/pages/Articles/HectareArticle";
import InvestmentArticle from "./Outside App/pages/Articles/InvestmentArticle";
import Fti from "./Outside App/pages/Articles/Fti";
import GSwS from "./Outside App/pages/Articles/GSwS";
import LtB from "./Outside App/pages/Articles/LtB";
import RM from "./Outside App/pages/Articles/RM";
import OTC from "./Outside App/pages/Articles/OTC";
import LTC from "./Outside App/pages/Articles/LTC";
import WiD from "./Outside App/pages/Articles/WiD";
import WiaP from "./Outside App/pages/Articles/WiaP";
import ArticleRoutes from "./ArticleRoutes";
import Wa_ecuador from "./Outside App/pages/Whatsapp/wa_ecuador";
import USAVisa from "./Outside App/pages/Articles/USAVisa";
import BalsaWood from "./Outside App/pages/BalsaWood/BalsaWood";
import TeakWood from "./Outside App/pages/TeakWood/TeakWood";

export const ThemeContext = createContext("null");

function App() {
  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className="App">
        <div className="app_body">
          <AuthContextProvider>
            <Routes>
              <Route path="/wa_ecuador" element={<Wa_ecuador />} />
              <Route path="/LogIn" element={<LogIn />} />
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
              <Route path="/SignUp" element={<SignUp />} />
              <Route element={<Menu />}>
                {ArticleRoutes}
                <Route
                  path="/"
                  element={
                    <>
                      <Landing title="Home" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/learn"
                  element={
                    <>
                      <Learn title="Learn" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/support"
                  element={
                    <>
                      <Support title="Support" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/agricultural_funds"
                  element={
                    <>
                      <Invest title="agricultural_funds" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/banking_funds"
                  element={
                    <>
                      <Crypto title="banking_funds" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/balsa"
                  element={
                    <>
                      <BalsaWood title="balsa" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/teak"
                  element={
                    <>
                      <TeakWood title="teak" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/library"
                  element={
                    <>
                      <Library title="Library" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/Article/What_is_a_Hectare"
                  element={
                    <>
                      <HectareArticle title="What is a Hectare?" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/Article/What_is_an_Investment"
                  element={
                    <>
                      <InvestmentArticle title="What is an Investment?" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/Article/What_is_a_Fixed_Term_Investment"
                  element={
                    <>
                      <Fti title="What is a Fixed Term Investment?" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/Article/USA_Visa"
                  element={
                    <>
                      <USAVisa title="USA Visa" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/Article/Getting_Started_with_Shares"
                  element={
                    <>
                      <GSwS title="Getting Started with Shares" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/Article/Loaning_to_Banks"
                  element={
                    <>
                      <LtB title="Loaning to Banks" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/Article/Risk_Management"
                  element={
                    <>
                      <RM title="Risk Management" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/Article/OneTime_Crops"
                  element={
                    <>
                      <OTC title="OneTime Crops" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/Article/LifeTime_Crops"
                  element={
                    <>
                      <LTC title="LifeTime Crops" />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/Article/What_is_a_Portafolio"
                  element={
                    <>
                      <WiaP title="What is a Portafolio?" />
                      <Footer />
                    </>
                  }
                />

                <Route
                  path="/Article/What_is_Diversification"
                  element={
                    <>
                      <WiD title="What is Diversification?" />

                      <Footer />
                    </>
                  }
                />

                <Route path="*" element={<P404 />} />
              </Route>

              <Route element={<ProtectedRoutes />}>
                <Route path="/" element={<Header />}>
                  <Route path="/Investing" element={<Investing />} />
                  <Route path="/Investments" element={<Investments />} />
                  <Route
                    path="/Investments/Agriculture"
                    element={<Agriculture />}
                  />
                  <Route
                    path="/Investments/Agriculture/Balsa"
                    element={<Balsa />}
                  />
                  <Route path="/Investments/Funds" element={<Funds />} />
                  <Route path="/Cash" element={<Cash />} />
                  <Route path="/FreeTrees" element={<FreeTrees />} />
                  <Route path="/Account" element={<Account />}>
                    <Route
                      path="/Account/Investing"
                      element={<Account_Investing />}
                    />
                    <Route path="/Account/Cryto" element={<Account_Cryto />} />
                    <Route
                      path="/Account/Transfers"
                      element={<Account_Transfers />}
                    />
                    <Route
                      path="/Account/Recurring"
                      element={<Account_Recurring />}
                    />
                    <Route
                      path="/Account/Statements"
                      element={<Account_Statements />}
                    />
                    <Route
                      path="/Account/History"
                      element={<Account_History />}
                    />
                    <Route path="/Account/Help" element={<Account_Help />} />
                    <Route
                      path="/Account/Settings"
                      element={<Account_Settings />}
                    >
                      <Route
                        path="/Account/Settings/PersonalInfo"
                        element={<Account_Settings_PersonalInfo />}
                      />
                      <Route
                        path="/Account/Settings/SecurityAndPrivacy"
                        element={<Account_Settings_SecurityAndPrivacy />}
                      />
                      <Route
                        path="/Account/Settings/Beneficiaries"
                        element={<Account_Settings_Beneficiaries />}
                      />
                      <Route
                        path="/Account/Settings/AppAppearance"
                        element={
                          <Account_Settings_AppAppearance
                            setTheme={() => toggleTheme()}
                          />
                        }
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Routes>
          </AuthContextProvider>
        </div>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
